@import 'rsuite/dist/rsuite.css';

span.rs-picker-toggle-placeholder {
    color: #131126 !important;
    font-family: 'Inter', sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    cursor: pointer !important;
}
.rs-picker-input-group {
    padding-left: 25px;
    width: 100%;
}

.ping-animation {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes ping {
    75%,
    100% {
        transform: scale(1.7);
        opacity: 0;
    }
}

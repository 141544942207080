.bg-custom-gradient {
    background: linear-gradient(
        to right,
        rgba(249, 250, 251, 0) 0%,
        rgba(249, 250, 251, 0.85) 5%,
        rgba(249, 250, 251, 1) 10%,
        rgba(249, 250, 251, 1) 20%,
        rgba(249, 250, 251, 1) 30%,
        rgba(249, 250, 251, 1) 40%,
        rgba(249, 250, 251, 1) 50%,
        rgba(249, 250, 251, 1) 60%,
        rgba(249, 250, 251, 1) 70%,
        rgba(249, 250, 251, 1) 80%,
        rgba(249, 250, 251, 1) 90%,
        rgba(249, 250, 251, 0.85) 95%,
        rgba(249, 250, 251, 0) 100%
    );
}

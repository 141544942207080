@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes pulse {
    0% {
        text-shadow: 0 0 0 rgba(255, 0, 0, 0.7);
    }
    50% {
        text-shadow: 0 0 7px rgba(255, 0, 0, 0.25);
    }
    /* 100% {
        text-shadow: 0 0 0 rgba(255, 0, 0, 0.7);
    } */
}

.react-datepicker__input-container.react-datepicker__view-calendar-icon {
    display: flex !important;
    gap: 8px !important;
    align-items: center !important;
}

.react-datepicker__input-container.react-datepicker__view-calendar-icon input {
    /* @apply font-normal text-sm text-neutral-900 leading-5 */
    color: rgb(19, 17, 38);
    font-weight: 400;
    line-height: 1.25rem /* 20px */;
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    max-width: 131px !important;
    padding: 14px 10px 14px 48px !important;
    outline: none !important;
    min-width: 100% !important;
    border: 1px solid #d3d2d8 !important;
    border-radius: 6px !important;
    cursor: pointer !important;
}

.react-datepicker__input-container.react-datepicker__view-calendar-icon input:disabled {
    color: rgb(211 210 216);
    cursor: not-allowed !important;
}

svg.react-datepicker__calendar-icon {
    padding: 0px !important;
    height: 24px !important;
    width: 24px !important;
    margin-left: 16px !important;
}

.react-datepicker-wrapper {
    max-width: 196px !important;
    width: 100% !important;
}

.react-datepicker__header {
    background-color: transparent !important;
    border-bottom: 1px solid #d3d2d8 !important;
}

.react-datepicker-popper {
    padding-bottom: 30px !important;
}

.react-datepicker__navigation-icon--previous::before {
    display: none !important;
}

.react-datepicker__navigation-icon--next::before {
    display: none !important;
}

span.react-datepicker__navigation-icon.react-datepicker__navigation-icon--previous {
    transform: rotate(90deg);
    right: -7px;
    background-image: url('../../../Assets/chevron-down.svg');
    background-position: center;
    background-repeat: no-repeat;
    height: 12px !important;
    width: 12px !important;
}

span.react-datepicker__navigation-icon.react-datepicker__navigation-icon--next {
    transform: rotate(-90deg);
    right: -7px;
    background-image: url('../../../Assets/chevron-down.svg');
    background-position: center;
    background-repeat: no-repeat;
    height: 12px !important;
    width: 12px !important;
}

.react-datepicker__current-month {
    /* @apply paragraph-small-medium !important; */
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
    background-color: #6c5dd3 !important;
    color: white !important;
}

.react-datepicker__week div:hover {
    background-color: #e2dff6 !important;
    color: black !important;
}

.react-datepicker {
    font-family: 'Inter', sans-serif !important;
    color: #131126;
    font-weight: 400;
}

.react-datepicker__week div {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    width: 34px !important;
    height: 30px !important;
    margin: 0px !important;
    padding-top: 5px !important;
}

.react-datepicker__week {
    display: flex !important;
    flex-direction: row !important;
    height: 30px !important;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important ;
}

.react-datepicker__day-name {
    width: 30px !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    font-family: 'Inter', sans-serif !important;
    color: #131126;
    font-weight: 400;
}

/* In your global CSS file (e.g., index.css) */

/* Disable text selection globally */
* {
    /* user-select: none; */
}

/* Enable text selection for input fields */
input,
textarea {
    user-select: text;
}

@layer components {
    .display-large-medium {
        @apply font-medium text-6xl text-neutral-900 leading-[72px] -tracking-[0.02em];
    }

    .display-large-semi-bold {
        @apply font-semibold text-6xl text-neutral-900 leading-[72px] -tracking-[0.02em];
    }

    .display-large-bold {
        @apply font-bold text-6xl text-neutral-900 leading-[72px] -tracking-[0.02em];
    }

    .display-large-extra-bold {
        @apply font-extrabold text-6xl text-neutral-900 leading-[72px] -tracking-[0.02em];
    }

    .display-small-medium {
        @apply font-medium text-[44px] leading-[48px] -tracking-[0.02em];
    }

    .display-small-semi-bold {
        @apply font-semibold text-[44px] text-neutral-900 leading-[48px] -tracking-[0.02em];
    }

    .display-small-bold {
        @apply font-bold text-[44px] text-neutral-900 leading-[48px] -tracking-[0.02em];
    }

    .display-small-extra-bold {
        @apply font-extrabold text-[44px] text-neutral-900 leading-[48px] -tracking-[0.02em];
    }

    .heading-h1-medium-desktop {
        @apply font-medium text-[40px] text-neutral-900 leading-[48px] -tracking-[0.02em];
    }

    .heading-h1-semi-bold-desktop {
        @apply font-semibold text-[40px] text-neutral-900 leading-[48px] -tracking-[0.02em];
    }

    .heading-h1-bold-desktop {
        @apply font-bold text-[40px] text-neutral-900 leading-[48px] -tracking-[0.02em];
    }

    .heading-h1-extra-bold-desktop {
        @apply font-extrabold text-[40px] text-neutral-900 leading-[48px] -tracking-[0.02em];
    }

    .heading-h2-medium-desktop {
        @apply font-medium text-4xl text-neutral-900 leading-[44px] -tracking-[0.02em];
    }

    .heading-h2-semi-bold-desktop {
        @apply font-semibold text-4xl text-neutral-900 leading-[44px] -tracking-[0.02em];
    }

    .heading-h2-bold-desktop {
        @apply font-bold text-4xl text-neutral-900 leading-[44px] -tracking-[0.02em];
    }

    .heading-h2-extra-bold-desktop {
        @apply font-extrabold text-4xl text-neutral-900 leading-[44px] -tracking-[0.02em];
    }

    .heading-h3-medium-desktop {
        @apply font-medium text-[32px] text-neutral-900 leading-10 -tracking-[0.02em];
    }

    .heading-h3-semi-bold-desktop {
        @apply font-semibold text-[32px] text-neutral-900 leading-10 -tracking-[0.02em];
    }

    .heading-h3-bold-desktop {
        @apply font-bold text-[32px] text-primary-500 leading-10 -tracking-[0.02em];
    }

    .heading-h3-extra-bold-desktop {
        @apply font-extrabold text-[32px] text-neutral-900 leading-10 -tracking-[0.02em];
    }

    .heading-h4-medium-desktop {
        @apply font-medium text-[28px] text-neutral-900 leading-9 -tracking-[0.02em];
    }

    .heading-h4-semi-bold-desktop {
        @apply font-semibold text-[28px] text-neutral-900 leading-9 -tracking-[0.02em];
    }

    .heading-h4-bold-desktop {
        @apply font-bold text-[28px] text-neutral-900 leading-9 -tracking-[0.02em];
    }

    .heading-h4-extra-bold-desktop {
        @apply font-extrabold text-[28px] text-neutral-900 leading-9 -tracking-[0.02em];
    }

    .heading-h5-medium-desktop {
        @apply font-medium text-2xl text-neutral-900 leading-8 -tracking-[0.02em];
    }

    .heading-h5-semi-bold-desktop {
        @apply font-semibold text-2xl text-neutral-900 leading-8 -tracking-[0.02em];
    }

    .heading-h5-bold-desktop {
        @apply font-bold text-2xl text-neutral-900 leading-8 -tracking-[0.02em];
    }

    .heading-h5-extra-bold-desktop {
        @apply font-extrabold text-2xl text-neutral-900 leading-8 -tracking-[0.02em];
    }

    .heading-h6-medium-desktop {
        @apply font-medium text-xl text-neutral-900 leading-7 -tracking-[0.02em];
    }

    .heading-h6-semi-bold-desktop {
        @apply font-semibold text-xl text-neutral-900 leading-7 -tracking-[0.02em];
    }

    .heading-h6-bold-desktop {
        @apply font-bold text-xl text-neutral-900 leading-7 -tracking-[0.02em];
    }

    .heading-h6-extra-bold-desktop {
        @apply font-extrabold text-xl text-neutral-900 leading-7 -tracking-[0.02em];
    }

    .heading-h6-medium-mobile {
        @apply font-medium text-lg text-neutral-900 leading-6 -tracking-[0.02em];
    }

    .heading-h6-semi-bold-mobile {
        @apply font-semibold text-lg text-neutral-900 leading-6 -tracking-[0.02em];
    }

    .heading-h6-bold-mobile {
        @apply font-bold text-lg text-neutral-900 leading-6 -tracking-[0.02em];
    }

    .heading-h6-extra-bold-mobile {
        @apply font-extrabold text-lg text-neutral-900 leading-6 -tracking-[0.02em];
    }

    .paragraph-large-regular {
        @apply font-normal text-lg text-neutral-900 leading-7;
    }

    .paragraph-large-medium {
        @apply font-medium text-lg text-neutral-900 leading-7;
    }

    .paragraph-large-semi-bold {
        @apply font-semibold text-lg text-neutral-900 leading-7;
    }

    .paragraph-large-underline {
        @apply font-normal text-lg text-neutral-900 leading-7 underline;
    }

    .paragraph-large-strikethrough {
        @apply font-normal text-lg text-neutral-900 leading-7 line-through;
    }

    .paragraph-large-italic {
        @apply font-normal text-lg text-neutral-900 leading-7 italic;
    }

    .paragraph-medium-regular {
        @apply font-normal text-base text-neutral-900 leading-6;
    }

    .paragraph-medium-medium {
        @apply font-medium text-base text-neutral-900 leading-6;
    }

    .paragraph-medium-semi-bold {
        @apply font-semibold text-base text-neutral-900 leading-6;
    }

    .paragraph-medium-underline {
        @apply font-normal text-base text-neutral-900 leading-6 underline;
    }

    .paragraph-medium-strikethrough {
        @apply font-normal text-base text-neutral-900 leading-6 line-through;
    }

    .paragraph-medium-italic {
        @apply font-normal text-base text-neutral-900 leading-6 italic;
    }

    .paragraph-small-regular {
        @apply font-normal text-sm text-neutral-900 leading-5;
    }

    .paragraph-small-medium {
        @apply font-medium text-sm text-neutral-900 leading-5;
    }

    .paragraph-small-semi-bold {
        @apply font-semibold text-sm text-neutral-900 leading-5;
    }

    .paragraph-small-underline {
        @apply font-normal text-sm text-neutral-900 leading-5 underline;
    }

    .paragraph-small-strikethrough {
        @apply font-normal text-sm text-neutral-900 leading-5 line-through;
    }

    .paragraph-small-italic {
        @apply font-normal text-sm text-neutral-900 leading-5 italic;
    }

    .paragraph-x-small-regular {
        @apply font-normal text-xs text-neutral-900 leading-5;
    }

    .paragraph-x-small-medium {
        @apply font-medium text-xs text-neutral-900 leading-5;
    }

    .paragraph-x-small-semi-bold {
        @apply font-semibold text-xs text-neutral-900 leading-5;
    }

    .paragraph-x-small-underline {
        @apply font-normal text-xs text-neutral-900 leading-5 underline;
    }

    .paragraph-x-small-strikethrough {
        @apply font-normal text-xs text-neutral-900 leading-5 line-through;
    }

    .paragraph-x-small-italic {
        @apply font-normal text-xs text-neutral-900 leading-5 italic;
    }

    .paragraph-overline-small {
        @apply font-semibold text-xs text-neutral-900 leading-5 tracking-[1px];
    }

    .paragraph-overline-large {
        @apply font-semibold text-sm text-neutral-900 leading-5 tracking-[1px];
    }
}

/* Toggle switch css start  */
.switch-toggle input:checked ~ .switch-button {
    background-color: #6c5dd3;
}

.switch-toggle input:checked ~ .dot {
    transform: translateX(80%);
    background-color: #fff;
}

/* Toggle switch css end  */

/* checkbox styles start */

.checkbox-style input[type='checkbox'] {
    position: absolute;
    opacity: 0;
}

.checkbox-style input[type='checkbox'] + .checkbox-label {
    position: relative;
    width: 100%;
}

.checkbox-style input[type='checkbox'] + .checkbox-label::before {
    content: '';
    background: #fff;
    border-radius: 4px;
    display: inline-block;
    border: 1px solid #d3d2d8 !important;
    width: 24px;
    height: 24px;
    position: relative;
    top: 0;
    right: 0;
    left: auto;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    margin-right: 12px;
    /* transition: all 250ms ease; */
}

/* fully selected checkbox */
.checkbox-style input[type='checkbox']:checked + .checkbox-label::before {
    background-color: #6c5dd3;
    background-image: url('./Assets/checked.svg');
    background-position: center;
    background-repeat: no-repeat;
    border: none !important;
    box-shadow: none !important;
}

/* partially selected checkbox */
.checkbox-without-label input[type='checkbox']:indeterminate + .checkbox-without-label-visible::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #6c5dd3;
    background-image: url('./Assets/indeterminate.svg');
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 3px;
}

.checkbox-style input[type='checkbox']:focus + .checkbox-label:before {
    outline: none;
    border-color: rgb(21, 35, 73);
}

/* checkbox styles end */

/* checkbox without label styles start */
.checkbox-without-label {
    width: 24px;
    height: 24px;
    position: relative;
}

.checkbox-without-label > * {
    position: absolute;
}

.checkbox-without-label-visible {
    width: 24px;
    height: 24px;
    background: #fff;
    border: 1px solid #d3d2d8;
    border-radius: 4px;
}

.checkbox-without-label > input {
    z-index: 1;
    opacity: 0;
    left: 50%;
    top: 50%;
    transform: translatex(-50%) translatey(-50%);
    display: block;
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.checkbox-without-label > input:checked + .checkbox-without-label-visible {
    background-color: #6c5dd3;
    background-image: url('./Assets/checked.svg');
    background-position: center;
    background-repeat: no-repeat;
}

.checkbox-without-label > input:hover + .checkbox-without-label-visible {
    border-color: #d3d2d8;
}

.checkbox > input:hover:checked + .checkbox-visible {
    border-color: #6c5dd3;
}

.checkbox > input:focus + .checkbox-visible {
    border-color: #6c5dd3;
}

/* checkbox without label styles end */

/* Radio button styles start */

.radio-button input[type='radio'] {
    position: absolute;
    opacity: 0;
}

.radio-button input[type='radio'] + .radio-label {
    position: relative;
    width: 100%;
    cursor: pointer;
}

.radio-button input[type='radio'] + .radio-label::before {
    content: '';
    background: #ffffff;
    border-radius: 100%;
    border: 2px solid #d3d2d8;
    display: inline-block;
    width: 24px;
    height: 24px;
    position: relative;
    top: 0;
    right: 0;
    left: auto;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
}

.radio-button input[type='radio']:checked + .radio-label::before {
    background-color: #6c5dd3;
    box-shadow: inset 0 0 0 4px #ffffff;
    border: 2px solid #6c5dd3;
}

.radio-button input[type='radio']:focus + .radio-label:before {
    outline: none;
    border-color: #6c5dd3;
}

/* Radio button styles end */

.label-text-format .radio-label::before,
.checkbox-label::before {
    float: left;
}

/* Radio switch button styles start */

.radio-button-switch input[type='radio'] {
    position: absolute;
    opacity: 0;
}

.radio-button-switch input[type='radio'] + .radio-label {
    position: relative;
    width: 100%;
}

.radio-button-switch input[type='radio'] + .radio-label::after {
    content: '';
    background: #ffffff;
    border-radius: 100%;
    border: 2px solid #d3d2d8;
    display: inline-block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
}

.radio-button-switch input[type='radio']:checked + .radio-label::after {
    background-color: #6c5dd3;
    /* box-shadow: inset 0 0 0 4px #ffffff; */
    border: 2px solid #6c5dd3;
}

.radio-button-switch input[type='radio']:focus + .radio-label:after {
    outline: none;
    border-color: #6c5dd3;
}

/* Radio button switch styles end */

/* Scrollbar Style Start */

.scrollbar-style::-webkit-scrollbar-track {
    border: 1px solid #d3d2d8;
    border-radius: 20px;
    background-color: transparent;
    margin-bottom: 10px;
}

.scrollbar-style::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
}

.scrollbar-style::-webkit-scrollbar-thumb {
    background-color: #6c5dd3;
    border-radius: 20px;
}

/* Scrollbar Style End */

/* drop-down-icon-rotate css start */
.dropdown-icon [data-headlessui-state='open'] .drop-down-icon-rotate {
    transform: rotate(180deg);
}

[data-headlessui-state='open'] button {
    box-shadow: 0px 0px 0px 4px rgba(226, 223, 246, 1);
    border: 1px solid #a79ee5;
}

[data-headlessui-state='open'] button.remove-shadow {
    box-shadow: none;
    border: none;
}

/* drop-down-icon-rotate css end */

.credit-box .paragraph-x-small-medium {
    font-weight: 400;
}

/* sick sleder css start */

.login-slide {
    padding: 0 0 58px;
}

.login-slide .slick-dots,
.dashboard-slide .slick-dots,
.abandoned-cart-slide .slick-dots {
    bottom: 8px;
}

.login-slide .slick-dots li,
.dashboard-slide .slick-dots li,
.abandoned-cart-slide .slick-dots li {
    width: auto;
    height: auto;
    margin: 0 4px;
}

.dashboard-slide .slick-dots li,
.abandoned-cart-slide .slick-dots li {
    margin: 0 2px;
}

.login-slide .slick-dots li button,
.dashboard-slide .slick-dots li button,
.abandoned-cart-slide .slick-dots li button {
    width: 8px;
    height: 8px;
    background: #a79ee5;
    border-radius: 10px;
    padding: 0;
}

.dashboard-slide .slick-dots li button,
.abandoned-cart-slide .slick-dots li button {
    background: #c4beed;
}

.login-slide .slick-dots li.slick-active button {
    width: 40px;
    background: #ffffff;
}

.dashboard-slide .slick-dots li.slick-active button,
.abandoned-cart-slide .slick-dots li.slick-active button {
    width: 24px;
    background: #6c5dd3;
}

.login-slide .slick-dots li.slick-active button:before,
.dashboard-slide .slick-dots li.slick-active button:before,
.abandoned-cart-slide .slick-dots li.slick-active button:before {
    opacity: 0;
    color: transparent;
}

.login-slide .slick-dots li button:before,
.dashboard-slide .slick-dots li button:before,
.abandoned-cart-slide .slick-dots li button:before {
    font-size: 0;
    line-height: 0;
    position: relative;
    content: '';
    opacity: 0;
    color: transparent;
}

.dashboard-slide .slick-slide.slick-active,
.dashboard-slide .slick-slide.slick-active + div.slick-slide {
    margin-right: 20px;
    width: 303px !important;
}

.dashboard-slide .slick-slide.slick-active {
    margin-left: 16px;
}

/* sick sleder css end */

.table-row-bg tr:nth-child(odd) td {
    background: #ffffff;
}

.table-row-bg tr:nth-child(even) td {
    background: #fafafa;
}

.transition-effect {
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

/* phoneinputbox css start */

.phoneinputbox .phone-input-input {
    border: none;
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 3px;
    padding-left: 0;
    padding-right: 0;
    background: none;
    font-family: 'Metropolis Semi Bold';
    color: rgba(0, 0, 0, 0.87);
}

.phoneinputbox .phone-input-input:focus {
    box-shadow: none;
}

.phoneinputbox .phone-input-country-select-arrow {
    font-size: 26px;
    color: #000;
    opacity: 1;
    appearance: none;
}

.phoneinputbox .phone-input-country select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

.phoneinputbox .phone-input-country-icon--border {
    background-color: transparent;
    box-shadow: none;
    height: 24px;
    width: auto;
}

.phoneinputbox .phone-input-country-select {
    /* display: none; */
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

.phoneinputbox .phone-input-input {
    font-family: 'Inter', sans-serif;
    color: #131126;
    font-size: 14px;
    padding-top: 0px;
    padding-bottom: 0px;
    font-weight: 400;
    line-height: normal;
    width: 38px;
    outline: none;
}

.phoneinputbox .phone-input-country-icon-img {
    border-radius: 5px;
}

.remove-spin-button input[type='number']::-webkit-inner-spin-button,
.remove-spin-button input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

/* phoneinputbox css end */

.title-sap::before {
    position: absolute;
    content: '';
    background: #d3d2d8;
    width: 16px;
    height: 1px;
    left: -21px;
    top: 9px;
}

.kottitle-sap {
    position: relative;
}

.kottitle-sap::before {
    position: absolute;
    content: '';
    background: #d3d2d8;
    width: 24px;
    height: 1px;
    left: -29px;
    top: 10px;
}

.draggable-elements div {
    width: 100%;
    left: 0;
    right: 0;
}

.draggable-elements > div div:nth-child(even) {
    background-color: #fafafa;
}

/* order-type-mobile style start */

.order-type-mobile svg {
    display: none;
}

.order-type-mobile .mx-1 {
    margin: 0;
}

.order-type-mobile .px-4 {
    padding: 0;
}

.order-type-mobile button {
    position: relative;
    padding: 0;
    background-color: transparent;
    height: auto;
    width: auto;
    padding-top: 12px;
    padding-right: 64px;
    padding-bottom: 12px;
}

.order-type-mobile button::after {
    position: absolute;
    content: '';
    right: 0;
    background-color: #ffffff;
    background-image: url('./Assets/dine-in-white.svg');
    background-position: center center;
    background-repeat: no-repeat;
    width: 56px;
    height: 56px;
    border-radius: 100%;
    box-shadow:
        0px 8px 10px -6px rgba(16, 24, 40, 0.1),
        0px 20px 25px -5px rgba(16, 24, 40, 0.1);
}

.order-type-mobile button.dine-in-icon::after {
    background-image: url('./Assets/dine-in-white.svg');
}

.order-type-mobile button.take-away-icon::after {
    background-image: url('./Assets/orders-white.svg');
}

.order-type-mobile button.delivery-icon::after {
    background-image: url('./Assets/riders-white.svg');
}

.order-type-mobile button:hover {
    background-color: transparent;
}

.order-type-mobile span {
    padding: 4px 6px;
    background-color: #fafafa;
    color: #6c5dd3;
    border-radius: 6px;
}

.menu-order-items,
.close-icon,
.menu-order-type .addIcon {
    display: none;
}

.menu-order-type .menu-order-items,
.menu-order-type .close-icon {
    display: block;
}

/* order-type-mobile style end */

/* Apply offer pos top shadow start */

.top-shadow::before {
    position: absolute;
    content: '';
    box-shadow: 0px 2px 1px 0px rgba(16, 24, 40, 0.05);
    height: 2px;
    width: 100%;
    left: 0;
    right: 0;
    top: -2px;
}

/* Apply offer pos top shadow end */

/* Image overlay background start */
img {
    max-width: 100%;
    vertical-align: middle;
}

.gradient-overlay::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom right, #000000b0, transparent);
    border-radius: 8px;
}

/* Image overlay background end */

/* hide default hue selector in chromepicker start */

div[style='padding: 16px 16px 12px;'] {
    display: none;
}

.hue-vertical {
    min-height: 240px !important;
    max-width: 24px !important;
    border-radius: 4px !important;
    border: 1px #d3d2d8 solid;
}

.chrome-picker {
    width: 240px !important;
    background: rgb(255, 255, 255);
    border-radius: 2px;
    /* box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 4px 8px; */
    box-shadow: none !important;
    box-sizing: initial;
    font-family: Menlo;
}

.chrome-picker :first-child {
    padding-bottom: 100% !important;
    border-radius: 4px !important;
}

.hue-picker {
    max-width: 24px !important;
}

.hue-vertical :last-child {
    /* opacity: 0 !important; */
    position: relative !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

.hue-vertical :last-child ::before {
    content: '';
    left: -13.75px;
    top: 4px;
    position: absolute;
    background-image: url('./Assets/color-picker-handle.svg');
    background-position: center center;
    background-repeat: no-repeat;
    width: 51px;
    height: 12px;
}

/* hide default hue selector in chromepicker end */

/* table backgroud start */

.table-row-background tr:nth-child(odd) td {
    background: #ffffff;
}

.table-row-background tr:nth-child(even) td {
    background: #fafafa;
}

/* table backgroud end */

/* platform sales css start */
.apexcharts-text.apexcharts-pie-label {
    filter: none;
}

/* platform sales css end */

/* table td border raius css start */
.radius-td:last-child td:first-child {
    border-radius: 0 0 0 8px;
}

.radius-td:last-child td:last-child {
    border-radius: 0 0 8px 0;
}

/* table td border raius css end */

@media only screen and (min-width: 2000px) {
    /* min width unset css start */

    .min-width-unset {
        min-width: unset !important;
    }

    /* min width unset css end */
}

/* Hide spinner controls in WebKit browsers (Chrome, Safari, Edge) */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide spinner controls in Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .scrollbar-hide::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .scrollbar-hide {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}

@media only screen and (max-width: 1900px) {
    .login-slide .slick-list {
        padding: 0 0 28px;
    }

    .login-slide .slick-dots,
    .dashboard-slide .slick-dots,
    .abandoned-cart-slide .slick-dots {
        position: relative;
    }
}

@media only screen and (max-width: 1440px) {
    .login-slide {
        padding: 0 0 65px;
    }
}

@media only screen and (max-width: 991px) {
    .min-w-unset {
        min-width: unset !important;
    }
}

@media only screen and (max-width: 374px) {
    .dashboard-slide .slick-slide.slick-active,
    .dashboard-slide .slick-slide.slick-active + div.slick-slide {
        width: 280px !important;
    }
}

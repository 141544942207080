@import 'rsuite/dist/rsuite.css';

.rs-picker-toggle.rs-btn.rs-btn-default {
    height: 48px;
    padding-top: 7px;
    border: 1px rgb(211 210 216) solid;
}

svg.rs-picker-toggle-caret.rs-icon {
    top: 14px !important;
}

span.rs-picker-toggle-value {
    color: #131126 !important;
    @apply font-normal text-sm text-neutral-900 leading-5;
}

.rs-picker-popup-date {
    z-index: 100;
}

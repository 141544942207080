.circle-loader {
    margin: 0 0 0 0;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-left-color: #ffff;
    animation-name: loader-spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    position: relative;
    display: inline-block;
    vertical-align: top;
    box-sizing: content-box;
}
.circle-loader,
.circle-loader:after {
    border-radius: 50%;
    width: 1.2em;
    height: 1.2em;
}
.load-complete {
    -webkit-animation: none;
    animation: none;
    border-color: #fff;
    transition: border 500ms ease-out;
}
.load-error {
    -webkit-animation: none;
    animation: none;
    border-color: #fff;
    transition: border 500ms ease-out;
}
.checkmark {
    display: none;
    box-sizing: content-box;
}
.checkmark.draw {
    display: block;
    box-sizing: content-box;
}
.checkmark.error {
    display: block;
    box-sizing: content-box;
}

.checkmark.draw:after {
    opacity: 1;
    height: 0.6em;
    width: 0.3em;
    transform-origin: left top;
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
    content: '';
    left: 0.28em;
    top: 0.7em;
    position: absolute;
    animation-duration: 1.2s;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
}
.checkmark.error {
    position: absolute;
    left: -1.41em;
    top: 0.32em;
    width: 4em;
    height: 4em;
}
.checkmark.error:before,
.checkmark.error:after {
    position: absolute;
    content: ' ';
    height: 0.6em;
    width: 0.3px;
    background-color: #fff;
}
.checkmark.error:before {
    transform: rotate(45deg);
}
.checkmark.error:after {
    transform: rotate(-45deg);
}
@keyframes loader-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes checkmark {
    0% {
        height: 0;
        width: 0;
        opacity: 1;
    }
    20% {
        height: 0;
        width: 0.3em;
        opacity: 1;
    }
    40% {
        height: 0.6em;
        width: 0.3em;
        opacity: 1;
    }
    100% {
        height: 0.6em;
        width: 0.3em;
        opacity: 1;
    }
}
